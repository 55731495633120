import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Select, FormControl, InputLabel, Grid } from "@material-ui/core"

import Section from "../sections/section"
import CardBlock from "../blocks/card"
import Hero from "../sections/hero"
import Carousel from "../blocks/carousel"

const Demo = () => {
  // React.useEffect(() => {
  //   console.log("=== Demo ===")
  // })

  const data = useStaticQuery(graphql`
    query {
      strapi {
        colors(sort: "name") {
          id
          name
          background
          subtitleColor
          titleColor
        }
        blocks(sort: "name") {
          id
          name
          center
          displayCaption
          displayDescription
          displayImage
          displayLink
          displayOverlay
          displaySubtitle
          displayTitle
          imagePosition
          imageRatio
          imageWidth
        }
        sections(sort: "name") {
          id
          name
          height
          layout
          width
        }
      }
    }
  `)

  const { sections, blocks, colors } = data.strapi

  const [form, setForm] = React.useState({
    sectionType: "4",
    sectionColor: "3",
    blockType: "4",
    blockColor: "4",
    padding: true,
    count: 3,
  })

  const [section, setSection] = React.useState({
    padding: form.padding,
    title: "Démo",
    titleColor: "DARK",
    layout: "GRID",
    background: "TRANSPARENT",
  })

  const [block, setBlock] = React.useState({
    padding: form.padding,
    title: "Titre",
    subtitle: "Sous-titre",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.",
    url: "https://guslyon.fr",
    linkLabel: "Découvrir",
    type: "CUSTOM",
    body: null,
    background: "LIGHT",
    center: true,
    displayCaption: true,
    displayDescription: false,
    displayImage: true,
    displayLink: true,
    displayOverlay: false,
    displaySection: true,
    displaySubtitle: true,
    displayTitle: true,
    height: "SIZE_50",
    imageCaption: null,
    imagePosition: "TOP",
    imageRatio: "LANDSCAPE",
    imageUrl: null,
    imageWidth: "SIZE_50",
    subtitleColor: "SECONDARY",
    titleColor: "PRIMARY",
    transparent: false,
    width: "SIZE_33",
  })

  const handleChangeSectionType = e => {
    const sectionId = e.target.value
    // console.log("sectionId", sectionId)
    setForm({
      ...form,
      sectionType: sectionId,
    })
    const nextSection = sections.find(section => section.id === sectionId)
    // console.log("nextSection", nextSection)
    setSection({
      ...section,
      layout: nextSection.layout,
    })
    // console.log("section", section)
    setBlock({
      ...block,
      width: nextSection.width,
    })
    // console.log("block", block)
  }

  const handleChangeSectionColor = e => {
    const colorId = e.target.value
    // console.log("colorId", colorId)
    setForm({
      ...form,
      sectionColor: colorId,
    })
    const nextColor = colors.find(color => color.id === colorId)
    // console.log("nextColor", nextColor)
    setSection({
      ...section,
      ...nextColor,
    })
    // console.log("section", section)
  }

  const handleChangeBlockType = e => {
    const blockId = e.target.value
    // console.log("blockId", blockId)
    setForm({
      ...form,
      blockType: blockId,
    })
    const nextBlock = blocks.find(block => block.id === blockId)
    console.log("nextBlock", nextBlock)
    setBlock({
      ...block,
      ...nextBlock,
    })
    // console.log("block", block)
  }

  const handleChangeBlockColor = e => {
    const colorId = e.target.value
    // console.log("colorId", colorId)
    setForm({
      ...form,
      blockColor: colorId,
    })
    const nextColor = colors.find(color => color.id === colorId)
    // console.log("nextColor", nextColor)
    setBlock({
      ...block,
      ...nextColor,
      transparent: nextColor.background === "TRANSPARENT" ? true : false,
      padding: nextColor.background === "TRANSPARENT" ? false : true,
      background:
        nextColor.background === "TRANSPARENT"
          ? section.background
          : nextColor.background,
    })
    // console.log("block", block)
  }

  const handleChangePadding = e => {
    // console.log(e.target.value, section)
    const padding = JSON.parse(e.target.value)
    setForm({
      ...form,
      padding: padding,
    })
    setSection({
      ...section,
      padding: padding,
    })
    setBlock({
      ...block,
      padding: padding,
    })
  }

  const handleChangeCount = e => {
    // console.log(e.target.value)
    setForm({
      ...form,
      count: e.target.value,
    })
  }

  const Repeater = props =>
    Array.apply(null, { length: form.count }).map(() => props.children)

  const CustomSelect = props => (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Select
        native
        value={props.value}
        onChange={props.onChange}
        label={props.label}
        inputProps={{
          name: props.label,
          id: props.id,
        }}
      >
        {/* <option value="" /> */}
        {props.options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Select>
    </FormControl>
  )

  return (
    <React.Fragment>
      <Section layout="GRID" background="LIGHT" padding={true}>
        <Grid item xs={12} sm={6}>
          <CustomSelect
            label="Section type"
            id="section-type"
            value={form.sectionType}
            options={sections}
            onChange={handleChangeSectionType}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomSelect
            label="Section color"
            id="section-color"
            value={form.sectionColor}
            options={colors}
            onChange={handleChangeSectionColor}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomSelect
            label="Block type"
            id="block-type"
            value={form.blockType}
            options={blocks}
            onChange={handleChangeBlockType}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomSelect
            label="Block color"
            id="block-color"
            value={form.blockColor}
            options={colors}
            onChange={handleChangeBlockColor}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomSelect
            label="Padding"
            id="padding"
            value={form.padding}
            options={[
              { id: true, name: "Avec marges" },
              { id: false, name: "Sans marges" },
            ]}
            onChange={handleChangePadding}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomSelect
            label="Blocks count"
            id="count"
            value={form.count}
            options={Array.from(Array(10).keys()).map(number => ({
              id: number + 1,
              name: number + 1,
            }))}
            onChange={handleChangeCount}
          />
        </Grid>
      </Section>

      <Section {...section}>
        {section.layout === "GRID" && (
          <Repeater>
            <CardBlock {...block} />
          </Repeater>
        )}

        {section.layout === "CAROUSEL" && (
          <Carousel itemsCount={10}>
            <Hero {...block} />
          </Carousel>
        )}
      </Section>
    </React.Fragment>
  )
}

export default Demo
